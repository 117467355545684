<template>
<div>
  <Row>
    <Col span="24">
      <Form :model="formItem" :label-width="100" ref="userForm">
        <FormItem label="名称" prop="user_nick" >
          <Input v-model="formItem.user_nick" style="width: 600px"></Input>
        </FormItem>

        <FormItem label="账号" prop="user_name" >
          <Input v-model="formItem.user_name" style="width: 600px"></Input>
        </FormItem>

        <FormItem label="密码" prop="user_pwd" >
          <Input v-model="formItem.user_pwd" style="width: 600px"></Input>
        </FormItem>

        <FormItem label="状态" prop="user_level" >
          <Slider style="width: 600px" :step="5" :marks="marks" v-model="formItem.user_level" :min="0" :max="50"></Slider>
        </FormItem>

        <FormItem label="角色" prop="user_role" >
          <treeselect :value-consists-of="'LEAF_PRIORITY'" v-model="formItem.roles" :options="roles" style="width: 600px" :multiple="true"/>
        </FormItem>

<!--        <FormItem label="区域" prop="user_city" >-->
<!--          <Select multiple v-model="formItem.user_city" style="width: 600px">-->
<!--            <OptionGroup :label="item.zone_name" v-for="(item, index) in city" :key="index" >-->
<!--              <Option v-for="x in item.children" :value="x.zone_id" :key="x.zone_code">{{ x.zone_name }}</Option>-->
<!--            </OptionGroup>-->
<!--          </Select>-->
<!--        </FormItem>-->

        <FormItem>
          <Button size="large" type="primary" @click="submit()">确定</Button>
        </FormItem>

      </Form>
    </Col>
  </Row>
</div>
</template>

<script>
import api from "../../api/setup";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  name: "AddUser",
  components: { Treeselect },
  data: function(){
    return {
      formItem: {
        user_avatar: '',
        user_nick: '',
        user_name: '',
        user_pwd: '123456',
        user_level: 5,
        roles: [],
        // user_city: []
      },
      roles: [],
      marks: {
        5: {
          style: {
            color: '#19be6b'
          },
          label: this.$createElement('strong', '正常')
        },
        45: {
          style: {
            color: '#2b85e4'
          },
          label: this.$createElement('strong', '管理员')
        },
        0: {
          style: {
            color: '#e13d13'
          },
          label: this.$createElement('strong', '禁用')
        }
      },
      city: []
    }
  },
  methods: {
    submit: function(){
      this.$http.post(api.addUser, this.formItem).then(response => {
        if(response.data.success) {
          this.$Message.success('添加成功');
          this.formItem.user_nick = '';
          this.formItem.user_name = '';
          this.formItem.user_pwd = '123456';
          this.formItem.user_level = 5;
          this.formItem.roles = [];
          this.formItem.user_city = [];
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    }
  },
  mounted() {
    this.$emit('lastPage', '/setup/users')
    this.$http.get(api.getRoles).then(response => {
      if(response.data.success) {
        this.roles.push(...response.data.res);
      } else {
        this.$Message.error(response.data.err);
        console.log(response)
      }
    })
    // this.$http.get(api.getCityList).then(response => {
    //   if(response.data.success) {
    //     this.city = response.data.res;
    //   } else {
    //     this.$Message.error(response.data.err);
    //     console.log(response)
    //   }
    // })
  },
  destroyed() {
    this.$emit('lastPage', '')
  }
}
</script>

<style scoped>

</style>